export default {
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "account-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been created"])},
  "account-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account details"])},
  "account-has-not-been-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has not been selected"])},
  "account-imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been imported"])},
  "account-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account management"])},
  "account-management-documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account management documentation"])},
  "account-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account was successfully updated."])},
  "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "accounts-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accounts menu"])},
  "accounts-table": {
    "address-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Target address for the withdrawal."])},
    "gas-limit-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Sets gas limit for the transaction."])},
    "gas-price-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sets gas price for the transaction. The value is in GWei. If not set, then it'll be automatically calculated."])},
    "name-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the account."])},
    "one-time-password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required."])},
    "token-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token to withdraw. If not filled, then the base token will be withdrawn."])}
  },
  "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
  "activated-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated at"])},
  "active-snipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active snipes"])},
  "activity-log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity log"])},
  "add-new-pair-checker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new pair checker"])},
  "add-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add wallet"])},
  "additional-allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional allowance"])},
  "additional-buy-allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional buy allowance"])},
  "additional-sell-allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional sell allowance"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
  "administration": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User administration"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
      "trained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trained"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "disable-2fa-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA successfully disabled"])},
      "update-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User successfully updated"])}
    },
    "roles": {
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "snipeTester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe tester"])},
      "copyTrader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy trader"])}
    },
    "notification": {
      "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no active notifications"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new notification"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
      "successfully-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully created"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])}
    }
  },
  "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "amount-ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount ratio"])},
  "an-error-occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Error Occurred"])},
  "and-copy-year": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["&copy; ", _interpolate(_list(0)), ","])},
  "approval-gas-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval gas limit"])},
  "approval-gas-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval gas price"])},
  "are-you-sure-that-you-would-like-to-cancel-the-snipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure that you would like to cancel the snipe?"])},
  "are-you-sure-that-you-would-like-to-delete-the-snipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure that you would like to delete the snipe?"])},
  "authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])},
  "automatic-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic approval"])},
  "automatic-buy-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic buy approval"])},
  "automatic-sell-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic sell approval"])},
  "automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "available-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available amount"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "balance-was-successfully-hidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance was successfully hidden"])},
  "balance-was-successfully-unhidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance was successfully unhidden"])},
  "balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances"])},
  "baseamounttofloat-p-maximumfeeowed-store-getters-chaindetails-basetokenname-maximum-fee-owed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ", _interpolate(_list(1)), " maximum fee owed"])},
  "baseamounttofloat-p-maximumsnipeamount-store-getters-chaindetails-basetokenname-maximum-snipe-amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ", _interpolate(_list(1)), " maximum snipe amount"])},
  "baseamounttofloat-p-minimumsnipeamount-store-getters-chaindetails-basetokenname-minimum-snipe-amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ", _interpolate(_list(1)), " minimum snipe amount"])},
  "base-pair-checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic pair checkers"])},
  "scam-test-pair-checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scam test pair checkers"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "buy-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy approval"])},
  "buy-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy price"])},
  "buy-problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy problems"])},
  "buy-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy trigger"])},
  "buy-triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy triggers"])},
  "can-import-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can import accounts"])},
  "can-not-import-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot import accounts"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "clipboard-failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not copy to clipboard"])},
  "clipboard-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully copied to clipboard"])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "commissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions"])},
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "configuration-cannot-be-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration cannot be found"])},
  "configuration-has-not-been-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration has not been modified"])},
  "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "copy-commission-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy commission fees"])},
  "copy-snipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy snipe"])},
  "copy-trade": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy trade"])},
    "copy-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy account"])},
    "copier-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier account"])},
    "copied-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied account"])},
    "copier-account-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. The account which will be copy the selected copy trader account."])},
    "copyable-account-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy trader accounts"])},
    "no-copyable-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No copyable trader accounts found."])},
    "configuration-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "my-copyable-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My copy trader accounts"])},
    "create-copyable-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a copy trader"])},
    "create-copyable-account-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy trader account created successfully."])},
    "create-copyable-account-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. The account which will be copyable."])},
    "no-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No copy trade configurations found."])},
    "create-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create configuration"])},
    "configuration-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration created successfully"])},
    "configuration-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration updated successfully"])},
    "configuration-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration deleted successfully"])},
    "minimum-amount-per-snipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum amount per snipe"])},
    "minimum-amount-per-snipe-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifies the minimum amount per copied snipe. If it is not specified, then the minimum amount limit of the package will be applied."])},
    "maximum-amount-per-snipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum amount per snipe"])},
    "maximum-amount-per-snipe-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifies the maximum amount per copied snipe. If it is not specified, then the maximum amount limit of the package will be applied."])},
    "add-copy-trader-account-are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once an account becomes a copy trade account, then you cannot unpublish it. Are you sure that you want to do this?"])},
    "delete-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete configuration"])},
    "delete-configuration-are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure that you want to delete this configuration? After the configuration was deleted, you will not be able to use it."])},
    "no-copy-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No copy trade configurations found."])},
    "copy-configuration-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy configurations"])},
    "you-dont-have-copy-trade-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have copy trade accounts."])},
    "use-balance-ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use balance ratio"])},
    "use-balance-ratio-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically modify the copied snipe configurations' amount with the ratio of the copied and copier account balance. If turned off, it copies the snipe amount 1:1."])},
    "current-balance-ratio-is": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Current balance ratio is ", _interpolate(_list(0)), "x"])},
    "statistics": {
      "profit": {
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h PnL"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1m PnL"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1y PnL"])}
      },
      "no-copiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of copiers"])}
    }
  },
  "costs-baseamounttofloat-p-price-store-getters-chaindetails-basetokenname-per-month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Costs ", _interpolate(_list(0)), " ", _interpolate(_list(1)), " per month"])},
  "costs-p-fiatprice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Costs ", _interpolate(_list(0))])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "create-an-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "create-preset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create preset"])},
  "create-snipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create snipe"])},
  "create-snipe-automation-config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create snipe automation configuration"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
  "current-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
  "dark-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark mode"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
  "deposit-amount-usdt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " USDT"])},
  "deposit-availableamount-usdt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " USDT"])},
  "deposit-types": {
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "giftCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift code"])},
    "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher"])}
  },
  "deposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposits"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "done-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done / Failed"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "edit-preset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit preset"])},
  "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
  "edit-snipe-automation-config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit snipe automation configuration"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "empty-snipe-config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty snipe config"])},
  "enabled-sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled sources"])},
  "enter-voucher-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter voucher code"])},
  "snipe-activity-log": {
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "manual-snipe-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual snipe created"])},
    "manual-sell-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sell created"])},
    "start-buy-approvals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start buy approvals"])},
    "buy-approval-started": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buy approval started for ", _interpolate(_list(0))])},
    "buy-approval-finished": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buy approval finished for ", _interpolate(_list(0))])},
    "buy-approvals-finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy approvals finished"])},
    "approval-error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Public key ", _interpolate(_list(0)), " - approval error - ", _interpolate(_list(1))])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "buying-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying started"])},
    "buy-transaction-started": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buy transaction started for ", _interpolate(_list(0))])},
    "buy-transaction-success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buy transaction was successful for ", _interpolate(_list(0))])},
    "buy-transaction-failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buy transaction has failed for ", _interpolate(_list(0)), ": ", _interpolate(_list(1))])},
    "sell-transaction-started": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sell transaction started for ", _interpolate(_list(0))])},
    "sell-transaction-success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sell transaction was successful for ", _interpolate(_list(0))])},
    "sell-transaction-failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sell transaction has failed for ", _interpolate(_list(0)), ": ", _interpolate(_list(1))])},
    "buying-finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying finished"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])},
    "starting-buy-triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting buy triggers"])},
    "buy-trigger-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy trigger started"])},
    "pair-checkers-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair checkers started"])},
    "pair-checker-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair checker started"])},
    "pair-check-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair check cancelled"])},
    "pair-check-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair check was successful"])},
    "pair-check-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair check has failed"])},
    "start-selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start selling"])},
    "selling-finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selling finished"])},
    "sell-triggers-started": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sell triggers started for transaction for ", _interpolate(_list(0))])},
    "sell-trigger-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell trigger started"])},
    "sell-approvals-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start sell approvals"])},
    "sell-approval-started": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sell approval started for ", _interpolate(_list(0))])},
    "sell-approval-success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sell approval was successful for ", _interpolate(_list(0))])},
    "sell-approval-failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sell approval failed for ", _interpolate(_list(0))])},
    "sell-approvals-finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell approvals finished"])}
  },
  "snipe-problems": {
    "buy-transaction-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy transaction failed"])},
    "sell-transaction-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell transaction failed"])},
    "buy-approval-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy approval failed"])},
    "sell-approval-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell approval failed"])},
    "token-sniffer": {
      "request-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TokenSniffer request has failed"])},
      "flagged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The token was flagged as a scam"])},
      "low-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token check failed with too low score"])},
      "high-sell-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token check failed with too high sell fee"])},
      "low-sell-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token check failed with too low sell fee"])},
      "ownership-renounced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token check failed with ownership renounced"])}
    }
  },
  "snipe-status": {
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])},
    "manual-snipe-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual snipe created"])},
    "manual-sell-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sell created"])},
    "buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "bought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bought"])},
    "partially-bought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially bought"])},
    "buying-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying failed"])},
    "sell-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell trigger"])},
    "starting-buy-approvals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting buy approvals"])},
    "starting-sell-approvals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting sell approvals"])},
    "starting-buy-triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting buy triggers"])},
    "buy-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy approval"])},
    "sell-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell approval"])},
    "manual-sell-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sell approval"])},
    "manual-sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sell"])},
    "buy-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy trigger"])},
    "selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selling"])},
    "checking-the-pair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking the pair"])},
    "post-buy-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post buy pair check"])},
    "pre-buy-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre buy pair check"])},
    "pre-buy-trigger-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre buy trigger pair check"])},
    "buy-trigger-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy trigger cancelled"])},
    "buy-trigger-fired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy trigger fired"])},
    "buy-trigger-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy trigger timeout"])},
    "buy-trigger-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy trigger failed"])},
    "sell-trigger-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell trigger cancelled"])},
    "sell-trigger-fired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell trigger fired"])},
    "sell-trigger-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell trigger timeout"])},
    "sell-trigger-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell trigger failed"])},
    "pair-check-has-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair check has failed"])},
    "pair-check-has-passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair check has passed"])},
    "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
    "partially-sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially sold"])},
    "not-sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not sold"])}
  },
  "snipe-history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe history"])},
  "errors": {
    "copy-trader-role-required-for-account-creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only copy trader can create copy trade accounts"])},
    "imported-account-cannot-be-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imported account cannot be copied"])},
    "account-already-has-copy-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account already has copy configuration"])},
    "account-has-copy-trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts with active configurations cannot be copy accounts"])},
    "account-already-copyable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is already copyable"])},
    "cannot-copy-own-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot copy your own account"])},
    "copiable-account-cannot-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiable accounts cannot copy"])},
    "copy-trade-configuration-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy trade configuration not found"])},
    "account-not-copiable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected account is not copiable"])},
    "non-base-token-copied-snipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied snipes can only be created with base token"])},
    "copyable-trade-can-have-only-one-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied snipes can be only created with one account"])},
    "copyable-trade-can-only-use-base-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied snipes can only use base token to purchase"])},
    "automated-snipe-not-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated snipe is not enabled in your package"])},
    "password-reset-disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset is disabled"])},
    "gift-code-already-activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift code is already activated"])},
    "package-fee-entry-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package fee history entry not found"])},
    "account-balance-is-too-low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balance is too low"])},
    "package-fee-must-not-be-negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package fee must not be negative"])},
    "deposit-already-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit already exists"])},
    "two-factor-authentication-disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two factor authentication is disabled"])},
    "invalid-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid configuration, please contact the administrator"])},
    "cannot-verify-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot verify your email address, please contact the administrator"])},
    "registration-disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration is disabled"])},
    "registration-allowed-via-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration can only be done via invite code"])},
    "registration-not-allowed-for-this-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration is not allowed for this email"])},
    "password-parameter-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password parameter is mandatory for registration"])},
    "service-is-overloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service is overloaded. Try again later."])},
    "blockchain-service-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain service error"])},
    "token-details-disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token details are disabled"])},
    "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal server error"])},
    "other-user-account-cannot-be-accessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other user's account cannot be accessed"])},
    "invalid-secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid secret"])},
    "secret-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret is required"])},
    "email-update-disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email update is disabled"])},
    "validation-response-inconsistent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation response is inconsistent!"])},
    "invalid-voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid voucher"])},
    "voucher-already-activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher is already activated"])},
    "failed-to-redeem-voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to redeem voucher"])},
    "withdrawal-is-in-invalid-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal is in an invalid state for this operation"])},
    "secret-is-mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret is a mandatory parameter"])},
    "account-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account does not exists"])},
    "no-active-package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active package is required"])},
    "package-already-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package already cancelled"])},
    "package-cannot-be-switched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package cannot be switched"])},
    "package-fee-is-unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The previous package fee is unpaid"])},
    "account-limit-reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account limit reached"])},
    "debt-limit-reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt limit reached"])},
    "importing-account-is-not-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing account is not allowed in your package"])},
    "cannot-set-balance-on-simulated-chain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot set balances on non-simulated chains"])},
    "updating-another-user-account-is-prohibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating another user's account is prohibited"])},
    "updating-another-user-balance-is-prohibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating another user's balances is prohibited"])},
    "accessing-another-user-snipe-is-prohibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessing another users snipe is prohibited"])},
    "not-trained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can only be accessed if the user has been trained"])},
    "snipe-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe does not exists"])},
    "snipe-already-finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe is already finished or it was cancelled"])},
    "snipe-is-not-running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe is not running so cannot be cancelled"])},
    "transaction-amount-is-lower-than-the-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction amount is lower than the limit"])},
    "transaction-amount-is-higher-than-the-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction amount is higher than the limit"])},
    "token-sniffer-not-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token sniffer checker is not enabled in your package"])},
    "missing-buy-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no buy transaction"])},
    "too-high-amount-ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too high amount ratio"])},
    "price-fetch-failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to fetch price for the token"])},
    "maximum-number-of-parallel-snipes-exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of parallel snipes exceeded"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
    "bad-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad request"])},
    "resource-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource not found"])},
    "unhandled-initialization-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhandled initialization case"])},
    "no-block-chain-nodes-are-currently-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No block chain nodes are currently available"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors"])},
    "insufficient-funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient funds"])},
    "invalid-swap-parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid swap parameters"])},
    "non-simulated-transaction-not-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non simulated transaction must not occur in a simulated environment"])},
    "honeypot-is-request-has-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honeypot.is request has failed"])},
    "token-check-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token check failed"])},
    "simulation-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation failed"])},
    "transaction-config-is-mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction config is mandatory"])},
    "invalid-preset-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid snipe preset id"])},
    "high-buy-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High buy tax"])},
    "high-sell-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High sell tax"])},
    "withdrawal-callback-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal callback failed"])},
    "account-balance-disable-on-non-simulated-chain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balances must not be updated when simulation is disabled"])},
    "invalid-api-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid API key"])},
    "invalid-transaction-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid transaction count"])},
    "too-many-running-trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many trades running at the same time"])},
    "trade-cancellation-timeout-has-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade cancellation timeout has expired"])},
    "duplicate-authentication-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate authentication entry found"])},
    "transfer-from-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer from failed, please check allowance"])},
    "insufficient-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient balance"])},
    "insufficient-liquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient liquidity"])},
    "gas-price-too-low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas price is too low"])},
    "trade-is-not-running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade cannot be cancelled as it's not running"])},
    "trade-is-already-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade is already cancelled"])},
    "chain-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chain is required"])},
    "snipe-schema-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe schema is required"])},
    "too-many-enabled-sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many enabled sources"])},
    "invalid-email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address"])},
    "email-must-not-be-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must not be empty"])},
    "email-must-be-a-string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must be a string"])},
    "email-address-is-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is too long"])},
    "password-is-too-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is too short"])},
    "too-few-numbers-are-in-the-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too few numbers are in the password"])},
    "too-few-symbols-are-in-the-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too few symbols are in the password"])},
    "too-few-upper-case-characters-are-in-the-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too few upper case characters are in the password"])},
    "too-few-lower-case-characters-are-in-the-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too few lower case characters are in the password"])},
    "password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
    "transaction-id-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction id is required"])},
    "block-hash-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block hash is required"])},
    "block-number-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block number is required"])},
    "from-address-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From address is required"])},
    "to-address-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To address is required"])},
    "transaction-value-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction value is required"])},
    "gas-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas is required"])},
    "gas-price-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas price is required"])},
    "nonce-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonce is required"])},
    "user-id-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User id is required"])},
    "user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User cannot be found"])},
    "user-already-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already exists"])},
    "inviter-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter does not exist"])},
    "public-key-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public key is required"])},
    "amount-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])},
    "token-address-is-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token address is too long"])},
    "configuration-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration is required"])},
    "configuration-already-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration already exists"])},
    "automated-snipe-configurations-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of automated snipe configurations reached"])},
    "gas-limit-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas limit is required"])},
    "gas-price-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas price is required"])},
    "id-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id is required"])},
    "invite-code-is-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite code is too long"])},
    "name-is-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is too long"])},
    "name-is-too-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is too short"])},
    "pre-buy-check-problems-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-buy check problems is required"])},
    "post-buy-check-problems-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-buy check problems is required"])},
    "buy-problems-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy problems is required"])},
    "sell-problems-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell problems is required"])},
    "activity-log-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity log is required"])},
    "status-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status is required"])},
    "preset-name-must-be-a-string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset name must be a string"])},
    "preset-name-is-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset name is too long"])},
    "account-name-must-be-a-string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name must be a string"])},
    "account-name-is-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name is too long"])},
    "one-time-password-must-not-be-an-empty-string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time password must not be an empty string"])},
    "one-time-password-must-be-a-string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time password must be a string"])},
    "one-time-password-must-be-a-six-digit-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time password must be a six digit number"])},
    "one-time-password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time password is required"])},
    "one-time-password-incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect one time password"])},
    "one-time-password-already-validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time password is already validated"])},
    "password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
    "password-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is too long"])},
    "old-password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password is required"])},
    "new-password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password is required"])},
    "incorrect-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect password"])},
    "invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token"])},
    "token-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token is expired"])},
    "token-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token not found"])},
    "token-type-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token type"])},
    "too-many-requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many requests, please try again later."])},
    "missing-auth-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auth header is required"])},
    "substate-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substate is required"])},
    "description-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description is required"])},
    "package-id-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package id is required"])},
    "preset-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset not found"])},
    "preset-limit-exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset limit exceeded"])},
    "preset-name-must-not-be-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset name is required"])},
    "package-id-must-be-a-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package id must be a number"])},
    "limit-must-be-a-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit must be a number"])},
    "limit-must-be-at-least-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit must be at least 1"])},
    "limit-must-be-less-than-or-equal-to-1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit must be less than or equal to 1000"])},
    "invalid-wei-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Wei format"])},
    "password-is-too-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encryption key must be at least 10 characters long"])},
    "password-is-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encryption key must be less then 128 characters long"])},
    "callback-url-is-too-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback URL must be less then 512 characters long"])},
    "callback-url-must-be-a-string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback URL must be a string"])},
    "base-and-target-address-must-not-be-the-same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base and target address must not be the same"])},
    "address-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address is required"])},
    "api-key-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key is required"])},
    "api-key-must-be-less-then-128-characters-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key must be less then 128 characters long"])},
    "slippage-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage is required"])},
    "slippage-must-be-greater-than-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage must be greater than zero"])},
    "slippage-must-be-less-than-100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage must be less than 100"])},
    "slippage-must-be-a-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage must be a number"])},
    "automatic-approval-field-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic approval field is required"])},
    "automatic-approval-must-be-a-boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic approval must be a boolean"])},
    "approval-gas-limit-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval gas limit is required"])},
    "approval-gas-limit-must-be-bigger-than-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval gas limit must be bigger than 1"])},
    "approval-gas-limit-must-be-a-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval gas limit must be a number"])},
    "timeout-specification-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout specification is required"])},
    "amount-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])},
    "amount-ratio-must-be-greater-than-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount ratio must be greater than zero"])},
    "manual-must-be-a-boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual must be a boolean"])},
    "sell-base-amount-must-be-a-boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell base amount must be a boolean"])},
    "account-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is required"])},
    "account-does-not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account does not exist"])},
    "id-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id is required"])},
    "transactions-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions is required"])},
    "change-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change is required"])},
    "trigger-change-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger change is required"])},
    "check-deprecated-addresses-flag-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check deprecated addresses flag is required"])},
    "check-router-address-flag-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check router address flag is required"])},
    "check-for-mint-function-flag-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check for mint function flag is required"])},
    "check-for-delegate-call-flag-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check for delegate call flag is required"])},
    "check-for-transfer-from-function-flag-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check for transfer from function flag is required"])},
    "check-if-all-erc20-functions-implemented-flag-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check if all ERC20 functions implemented flag is required"])},
    "minimum-supply-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum supply is required"])},
    "minimal-age-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimal age is required"])},
    "maximum-buy-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum buy tax"])},
    "maximum-sell-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum sell tax"])},
    "minimum-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum score"])},
    "minimum-sell-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum sell fee"])},
    "maximum-sell-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum sell fee"])},
    "check-renounced-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check renounced flag"])},
    "type-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type is required"])},
    "invalid-uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid uuid"])},
    "pair-same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair same"])},
    "invalid-wei-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Wei format"])},
    "pair-same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair same"])},
    "pair-configuration-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair configuration is required"])},
    "invalid-trigger-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid trigger configuration"])},
    "check-if-all-erc20-functions-implemented-flag-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check if all ERC20 functions implemented flag is required"])},
    "trigger-change-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger change is required"])},
    "timeout-trigger-configuration-schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout trigger configuration schema"])},
    "slippage-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage is required"])},
    "slippage-must-be-a-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage must be a number"])},
    "base-and-target-address-must-not-be-the-same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base and target address must not be the same"])},
    "poll-interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll interval"])},
    "poll-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll count"])},
    "invalid-pair-checker-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid pair checker configuration"])},
    "type-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type is required"])},
    "amount-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])},
    "gas-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas limit"])},
    "id-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id is required"])},
    "transactions-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions is required"])},
    "trigger-change-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger change is required"])},
    "invalid-uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid uuid"])},
    "pair-same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair same"])},
    "automatic-approval-must-be-a-boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic approval must be a boolean"])},
    "invalid-trigger-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid trigger configuration"])},
    "automatic-approval-field-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic approval field is required"])},
    "api-key-must-be-a-string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key must be a string"])},
    "api-key-must-be-less-then-128-characters-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key must be less then 128 characters long"])},
    "amount-ratio-must-be-greater-than-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount ratio must be greater than zero"])},
    "amount-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])},
    "amount-ratio-must-be-greater-than-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount ratio must be greater than zero"])},
    "address-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address is required"])},
    "address-must-not-exceed-128-characters-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address must not exceed 128 characters length"])},
    "address-must-be-a-string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address must be a string"])},
    "invalid-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid address"])},
    "backend-service-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend service error, please contact the administrators"])},
    "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal server error"])},
    "key-pair-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key pair is mandatory"])}
  },
  "executed-buy-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed buy transactions"])},
  "executed-sell-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed sell transactions"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
  "expires-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires at"])},
  "expires-at-expirydate-tolocalestring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Expires at ", _interpolate(_list(0))])},
  "failed-to-hide-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to hide balance"])},
  "failed-to-load-snipe-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load snipe details"])},
  "failed-to-unhide-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to unhide balance"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
  "fee-deposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee deposits"])},
  "fee-list": {
    "account-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Account to deposit from."])},
    "gas-limit-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Limitation of the gas use for withdrawal."])},
    "gas-price-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas price to use for depositing in GWei. If not set, then it'll be automatically calculated."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee list"])}
  },
  "fee-payment-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee payment was successfully started"])},
  "fee-withdrawals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee withdrawals"])},
  "fee-withdrawal-states": {
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
    "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])}
  },
  "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
  "fees-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fees menu"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "gas-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas limit"])},
  "gas-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas price"])},
  "gas-used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas used"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "generates-a-secret-for-time-based-two-factor-authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generates a secret for time based two factor authentication."])},
  "gift-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift code"])},
  "gift-code-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift code list"])},
  "gift-codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift codes"])},
  "give-name-to-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give name to your account"])},
  "got": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got"])},
  "honeypot-is-checker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["honeypot.is checker"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
  "import-modal": {
    "address-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Address of the imported account."])},
    "private-key-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Private key of the imported account."])}
  },
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
  "languages": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])}
  },
  "leave-impersonate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impersonation left"])},
  "impersonate-success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Impersonated ", _interpolate(_list(0))])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
  "light-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light mode"])},
  "light-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light/Dark"])},
  "liquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidity"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "list-of-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])},
  "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
  "log-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
  "login": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "email-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
    "otp-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the one time password"])},
    "password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password"])}
  },
  "login-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully logged in"])},
  "logout-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully logged out"])},
  "manual-sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sell"])},
  "manual-sell-was-successfully-scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sell was successfully scheduled"])},
  "maximum-buy-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum buy tax"])},
  "maximum-liquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum liquidity"])},
  "maximum-sell-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum sell fee"])},
  "maximum-sell-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum sell tax"])},
  "minimum-liquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum liquidity"])},
  "minimum-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum score"])},
  "minimum-sell-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum sell fee"])},
  "more-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more options"])},
  "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "no-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accounts"])},
  "no-accounts-created-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accounts created yet"])},
  "no-accounts-found-in-the-buy-transactions-for-this-trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accounts found in the buy transactions for this trade."])},
  "no-activity-log-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No activity log found."])},
  "no-balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No balances"])},
  "no-buy-trigger-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No buy trigger configured."])},
  "no-buy-triggers-were-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No buy triggers were configured."])},
  "no-deposits-were-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deposits were found."])},
  "no-errors-were-detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No errors were detected."])},
  "no-gift-codes-are-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No gift codes are available"])},
  "no-pair-checkers-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pair checkers configured"])},
  "no-post-buy-checker-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No post-buy checker configured."])},
  "no-post-buy-pair-checkers-were-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No post-buy pair checkers were configured."])},
  "no-pre-buy-checker-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pre-buy checker configured."])},
  "no-pre-buy-pair-checkers-were-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pre-buy pair checkers were configured."])},
  "no-pre-buy-trigger-checker-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pre-buy trigger checker configured."])},
  "no-pre-buy-trigger-pair-checkers-were-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pre-buy trigger pair checkers were configured."])},
  "no-presets-are-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No presets are defined"])},
  "no-presets-created-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No presets created yet"])},
  "no-sell-configuration-has-been-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sell configuration has been added."])},
  "no-sell-trigger-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sell trigger configured."])},
  "no-sell-triggers-were-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sell triggers were configured."])},
  "no-snipe-signals-are-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No snipe signals are available"])},
  "no-snipes-created-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No snipes created yet"])},
  "no-snipes-were-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No snipes were found."])},
  "no-trade-configuration-has-been-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No trade configuration has been added."])},
  "no-transactions-were-configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transactions were configured."])},
  "no-transactions-were-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transactions were found."])},
  "no-users-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users found"])},
  "no-vouchers-were-redeemed-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vouchers were redeemed yet"])},
  "no-withdrawals-were-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No withdrawals were found."])},
  "not-a-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a member?"])},
  "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
  "not-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not paid"])},
  "notifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "maintenance-between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There is a planned maintenance between ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), ". Between these dates you may experience some service outage"])},
    "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
    "no-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently there are no active notifications"])},
    "successfully-dismissed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification dismissed"])}
  },
  "nothings-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing's here…"])},
  "one-time-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time password"])},
  "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
  "our-discord-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our Discord channel"])},
  "p-buycommission-100-commission-on-buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " % commission on buy"])},
  "p-maximumallowedaccounts-maximum-accounts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " maximum accounts"])},
  "p-maximumnumberofpresets-maximum-number-of-presets": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " maximum number of presets"])},
  "p-maximumparallelsnipes-maximum-parallel-snipes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " maximum parallel snipes"])},
  "p-maximumsnipehistorylength-finished-snipes-stored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " finished snipes stored"])},
  "p-sellcommission-100-commission-on-sell": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " % commission on sell"])},
  "p-withdrawalfee-100-withdrawal-fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " % withdrawal fee"])},
  "package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
  "package-activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package was successfully activated"])},
  "package-canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package was successfully canceled"])},
  "package-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package fees"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
  "packages-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packages menu"])},
  "page-currentpagehuman-of-numberofpages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Page ", _interpolate(_list(0)), " of ", _interpolate(_list(1))])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "pair-check-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair check configuration"])},
  "pair-checker-config-form": {
    "honeypot-is": {
      "maximum-buy-tax-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum value of the allowed buy tax in percents based on honeypot.is."])},
      "maximum-sell-tax-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum value of the allowed sell tax in percents based on honeypot.is."])}
    },
    "tokensniffer": {
      "maximum-sell-fee-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If configured, checks whether the sell fee is above the limit. Can be configured in percentage."])},
      "minimum-score-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If configured, checks whether the token sniffer score is below the limit."])},
      "minimum-sell-fee-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If configured, checks whether the sell fee is below the limit. Can be configured in percentage."])}
    },
    "type-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Type of the checker. For details of each checker, please read the sniping section of the user manual."])}
  },
  "pair-checker-types": {
    "pairCreatorAddressAgeChecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair creator address age checker"])},
    "baseAssetBalanceChecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base asset balance checker"])},
    "honeyPotIsChecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honeypot.is checker"])},
    "minimumBaseTokenSupplyChecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum base token supply checker"])},
    "tokenSnifferChecker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TokenSniffer checker"])}
  },
  "pair-checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair checkers"])},
  "paircheckerconfig-configuration-maximumbuytax": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "paircheckerconfig-configuration-maximumsellfee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "paircheckerconfig-configuration-maximumselltax": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "paircheckerconfig-configuration-minimumscore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "paircheckerconfig-configuration-minimumsellfee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "parsefloat-transactionconfig-approvalgasprice-1e9-gwei": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " GWei"])},
  "parsefloat-transactionconfig-gasprice-1e9-gwei": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " GWei"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "password-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password was successfully changed."])},
  "password-mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password mismatch"])},
  "password-reset": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset was requested. Please check your email for further instructions."])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password. The password should be at least 12 characters long, including at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character."])},
    "retype-password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password again."])}
  },
  "passwords-does-not-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords does not match"])},
  "pay-debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay debt"])},
  "please-take-into-account-that-the-already-executed-states-wont-be-reverted-only-the-execution-of-further-states-will-be-prevented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please take into account that the already executed states won't be reverted. Only the execution of further states will be prevented."])},
  "poll-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll count"])},
  "poll-interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll interval"])},
  "post-buy-check-problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-buy check problems"])},
  "post-buy-checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-buy checkers"])},
  "post-buy-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-buy pair check"])},
  "pre-buy-check-problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-buy check problems"])},
  "pre-buy-checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-buy checkers"])},
  "pre-buy-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-buy pair check"])},
  "pre-buy-trigger-checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-buy trigger checkers"])},
  "pre-buy-trigger-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-buy trigger pair check"])},
  "preset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset"])},
  "preset-cannot-be-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset cannot be found"])},
  "preset-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset was successfully created"])},
  "preset-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset was successfully deleted"])},
  "preset-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset details"])},
  "preset-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset info"])},
  "preset-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset list"])},
  "preset-name-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the preset which will be used as an unique identifier for the preset"])},
  "preset-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset was successfully updated"])},
  "presets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presets"])},
  "presets-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presets menu"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "price-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change"])},
  "price-change-triggered-take-profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change triggered take profit"])},
  "pricechange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "private-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private key"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "profile-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile menu"])},
  "profile-page": {
    "confirm-new-password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. New password one more time"])},
    "email-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Your e-mail"])},
    "name-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Your name"])},
    "new-password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Your new password"])},
    "password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Your current password"])}
  },
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
  "purchased-targeted-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchased / targeted amount"])},
  "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
  "read-or-reset-a-secret-for-time-based-two-factor-authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read or reset a secret for time based two factor authentication."])},
  "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
  "redeem-voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem voucher"])},
  "register": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "email-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
    "invite-code-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your invite code"])},
    "name-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your full name."])},
    "password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password. The password should be at least 12 characters long, including at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character."])},
    "retype-password-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password again."])}
  },
  "register-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully registered"])},
  "remaining-tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining tokens"])},
  "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember"])},
  "required-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required."])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "retype-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retype password"])},
  "schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schema"])},
  "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "search-ctrl-k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search (ctrl+k)"])},
  "search-for-name-or-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for name or email"])},
  "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
  "copysellonprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy fee on profit"])},
  "copy-commission-on-sell-profit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " % copy commission on profit"])},
  "sell-automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell automatically"])},
  "sell-problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell problems"])},
  "sell-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell trigger"])},
  "sell-trigger-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell trigger configuration"])},
  "sell-triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell triggers"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "shuutr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shuutr"])},
  "shuutr-logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shuutr logo"])},
  "signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signals"])},
  "simple-price-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple price change"])},
  "simple-price-change-with-smart-take-profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple price change with smart take profit"])},
  "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage"])},
  "snipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe"])},
  "snipe-automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation"])},
  "snipe-automation-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation enabled"])},
  "snipe-automation-disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation disabled"])},
  "snipe-automation-config-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation configuration created"])},
  "snipe-automation-config-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation configuration was deleted"])},
  "snipe-automation-config-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation configuration details"])},
  "snipe-automation-config-form": {
    "account-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Account which should be used for the transaction."])},
    "amount-help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Required. Amount of the transaction in ", _interpolate(_list(0)), "."])},
    "name-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the configuration which will be used as an unique identifier"])},
    "price-change-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change level for triggering a sell. For example, when 200 is set, the purchased amount will be sold when it reaches double price compared to the initial price."])},
    "stop-loss-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailing stop loss level for triggering a sell. For example, when 70 is set, the purchased amount will be sold when the price drops to the 70% of the maximum price since the purchase."])},
    "timeout-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout for all sell triggers in seconds. For example, when 300 is set and no other sell triggers were firing for 300 seconds, then the token will be sold and all other triggers will be cancelled."])}
  },
  "snipe-automation-config-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Snipe automation configuration which will be used"])},
  "snipe-automation-config-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation configuration list"])},
  "snipe-automation-config-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation configuration updated"])},
  "snipe-automation-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe automation signals"])},
  "snipe-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe was successfully cancelled"])},
  "snipe-configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe configuration"])},
  "snipe-configuration-form": {
    "account-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Account which should be used for the transaction."])},
    "advanced": {
      "token-address-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses of source and target tokens. Empty means the base token should be used"])}
    },
    "price-change-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Price change level for triggering a sell. For example, when 200 is set, the purchased amount will be sold when it reaches double price compared to the initial price."])},
    "schema-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Schema to select."])},
    "simple-nx-with-take-profit": {
      "take-profit-level-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Take profit level for selling the token after the price dropped below this level. For example, when 200 is set, the token will be automatically sold if it reaches triple price compared to the initial price and then dropped below twice the initial price."])},
      "take-profit-trigger-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Take profit trigger level for starting to watch a falling price. For example, when 300 is set, monitoring the take profit level will be started when it reaches triple price compared to the initial price."])}
    },
    "swap-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Swap to execute the snipe on."])},
    "target-address-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address of the target token."])}
  },
  "snipe-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe was successfully deleted"])},
  "snipe-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe details"])},
  "snipe-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe list"])},
  "snipe-search": {
    "filter": {
      "showed-states": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displayed states"])},
      "stateGroup": {
        "buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying"])},
        "selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selling"])},
        "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])}
      }
    }
  },
  "snipe-states": {
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "pre-buy-trigger-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-buy trigger pair check"])},
    "pre-buy-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-buy pair check"])},
    "post-buy-pair-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-buy pair check"])},
    "buy-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy approval"])},
    "buy-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy trigger"])},
    "buy-retry-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy retry trigger"])},
    "sell-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell approval"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "manual-sell-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sell approval"])},
    "manual-sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sell"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "partially-filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially filled"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])}
  },
  "snipe-was-successfully-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe was successfully created"])},
  "sniperbot-logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SniperBot logo"])},
  "snipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipes"])},
  "snipes-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["snipes menu"])},
  "sniping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sniping"])},
  "sniping-documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sniping documentation"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "state-of-the-snipe-where-the-selling-happens-in-parallel-for-all-the-defined-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of the snipe where the selling happens in parallel for all the defined transactions."])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "stop-loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop loss"])},
  "stoplosschange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "store-getters-chaindetails-basetokenname-balance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " balance"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "successfully-enabled-2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully enabled 2FA"])},
  "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
  "swap-contract-creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap contract creation"])},
  "take-profit-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take profit level"])},
  "take-profit-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take profit trigger"])},
  "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
  "there-are-no-active-snipe-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no active snipe signals"])},
  "there-are-no-automated-snipe-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no automated snipe configurations"])},
  "there-are-no-fee-deposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no fee deposits"])},
  "there-are-no-fee-withdrawals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no fee withdrawals"])},
  "there-are-no-package-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no package fees"])},
  "there-are-no-copy-commission-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no copy commission fees"])},
  "this-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this link"])},
  "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
  "token-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token address"])},
  "token-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token expired"])},
  "token-will-expire-in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Token will expire in ", _interpolate(_list(0)), " minutes, please log in again"])},
  "tokensniffer-checker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokensniffer checker"])},
  "top-snipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top snipes"])},
  "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade"])},
  "trade-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade configurations"])},
  "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades"])},
  "trading-enabled-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading enabled flag"])},
  "trailing-stop-loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailing stop loss"])},
  "transaction-config-form": {
    "account-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Account which should be used for the transaction."])},
    "amount-help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Required. Amount of the transaction in ", _interpolate(_list(0)), "."])},
    "amount-ratio-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifies how much of the amount should be sold. For example, 50 would mean that half of the amount will be sold."])},
    "approval-gas-limit-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Sets gas limit for the approval transaction."])},
    "approval-gas-price-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sets gas price for the approval transaction. The value is in GWei. If not set, then it'll be automatically calculated."])},
    "gas-limit-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Sets gas limit for the transaction."])},
    "gas-price-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sets gas price for the transaction. The value is in GWei. If not set, then it'll be automatically calculated."])},
    "sell-amount-ratio-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratio to sell in percents. For example, 50 would mean that the half of the bought amount will be sold."])},
    "slippage-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. The value of slippage in percents"])},
    "timeout-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. The timeout value which will be passed to the blockchain. It can be specified in seconds. The blockchain will reject the transaction if it will not be processed in this time."])}
  },
  "transactionconfig-amountratio-100": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "transactionconfig-slippage-100": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "transactionconfig-timeout-s": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
  "transactionconfig-account-account-has-not-been-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has not been selected"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
  "trigger-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger change"])},
  "trigger-config-form": {
    "change-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Price change in percents where the trigger should fire. For example, 50 means half of the original price and 200 means double of the original price."])},
    "poll-count-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of trigger checks before stopping the execution of the transaction. If it's not set, the polling will continue until the trigger is not satisfied or cancelled. Polls are usually done once per period of block creation. For example, 3 seconds on BSC."])},
    "poll-interval-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In seconds"])},
    "trailing-stop-loss-change-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Price change in percents where the trigger should fire. For example, 50 means half of the original price."])},
    "trigger-change-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Price change in percents where the trigger should switch to fall monitoring. For example, 150 means at 1.5 times of the original price."])},
    "type-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. Type of the trigger. For details of each trigger, please read the sniping section of the user manual."])},
    "timeout-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required. The number of seconds after the trigger times out."])}
  },
  "trigger-types": {
    "priceChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change"])},
    "swapContractCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap contract creation"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
    "tradingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading enabled"])},
    "priceChangeTriggeredTakeProfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change triggered take profit"])},
    "priceLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price level"])},
    "trailingStopLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailing stop loss"])}
  },
  "triggerconfig-configuration-change-1-100": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "triggerconfig-configuration-triggerchange-1-100": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " %"])},
  "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggers"])},
  "two-factor-authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two factor authentication"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TxId"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "type-has-not-been-selected-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type has not been selected yet."])},
  "unhandled-exception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhandled exception"])},
  "update-user-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update user profile"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "usdt-per-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USDT per month"])},
  "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "user-guide": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User guide"])},
    "accounts": {
      "general": {
        "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can manage and view your current account in the"])},
        "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". This page lists all of the available accounts on the current chain. You can click on the account's address to view it on the current chain's explorer site, like bscscan for the BSC chain and etherscan for the ETH chain. Furthermore, it lists the account balances. It shows the base token balance and the value of all the other token balances in the base token currency. To view the details of a certain account or do some action on them, you can use the action buttons in the certain row. For details, please check the relevant section below."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
      },
      "import": {
        "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To import a chain to the currently active chain, please open the"])},
        "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", then click on the plus sign in the right top corner of the accounts table. After that, a popup should occur. In that, you should click on the \"Import\" button. After that, the \"Account has been imported\" message and a new line in the table should appear."])},
        "section-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be aware that importing accounts may be disabled in your package."])},
        "section-1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" If you don't know whether your package allows to import accounts, please check the"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import an account"])}
      },
      "modify": {
        "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, accounts are referred by their address. However, when multiple accounts are in use it can be hard to remember which is which. To make this software easier to use, you can give name to the accounts. If the account has a name, then in all other views it'll be refered by it's name instead of it's address."])},
        "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To set the name of an account, please open the"])},
        "section-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this menu, find the account's row what you would like to rename. In the row, click on the button with the cogwheel icon. This should bring up a popup menu where you could type in the desired name. If you click on the \"Done\" button, the name should be immediately updated."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify accounts"])}
      },
      "new": {
        "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create a chain on the currently active chain, please open the"])},
        "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", then click on the plus sign in the right top corner of the accounts table. After that, a popup should occur. In that, you should click on the \"Create\" button. After that, the \"Account has been created\" message and a new line in the table should appear."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New accounts"])}
      },
      "read": {
        "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To view the details of an account, please open the"])},
        "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", then find the account's row. In that row, please click on the button with the eye icon on it. It would redirect you to a page where you will see all the balances of the account in details, the deposits, withdrawals, transactions and snipes made with that account."])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details of an account"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read account details"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account management documentation"])}
    },
    "authentication": {
      "2fa": {
        "disable": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To disable two factor authentication secret, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". There you have to click on the \"Generate\" button in the \"Two factor authentication\" section. When you've clicked on it, the generated QR code should immediately occur on the screen. After this, the secret must be validated by typing in the generated 2FA code based on the secret. When the secret is validated, the 2FA is activated. Please make sure that you save this 2FA secret, because if you lose it, you won't be able to login anymore. In this case, please contact the support."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable two factor authentication"])}
        },
        "read": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To read the currently active two factor authentication secret, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". There you have to fill the \"One time password\" field and click on the \"Read\" button in the \"Two factor authentication\" section. When you've clicked on it, the generated QR code should immediately occur on the screen."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get current secret"])}
        },
        "set-up": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To set up two factor authentication, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". There you have to fill the \"One time password\" field and click on the \"Reset\" button in the \"Two factor authentication\" section. When you've clicked on it, the two factor authentication should be immediately disabled."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up two factor authentication"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA"])}
      },
      "general": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page describes the available authentication related features. To achieve a high level of security, we recommend to use two factor authentication and change your password periodically (e.g. monthly). To ensure that secure passwords are used, we require a password at least 12 charaters long, including at least one lower case letter, one upper letter, one number and one symbol."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
      },
      "password": {
        "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password can be changed in \"Change password\" section of the"])},
        "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". There you'll have to type your current password and the new password twice. After you've filled the form, please click on the submit button and you should see a pop up which states \"Password has successfully changed\"."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
      },
      "profile": {
        "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service currently stores the name and the email address in the users profile. This information can be changed in \"Edit Profile\" section of the"])},
        "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". There you'll have to modify the data entry what you would like to update. After you've modified the data in the form, please click on the submit button and you should see a pop up which states \"User details were successfully updated.\"."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])}
    },
    "deposit": {
      "general": {
        "section-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this system, deposit processing is fully automatic. The supported blockchains are continously monitored for transactions of the created or imported accounts. So to create a new deposit, you'll only need to send some token to the account. Then the deposit will be detected and credited to your account."])},
        "section-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can easily copy your accounts' address by opening the"])},
        "section-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", then clicking on the copy action button it the row of the selected account. Then you can send token to this address by your preferred solution, like Metamask or Trust wallet."])},
        "section-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To check your deposits, please open the"])},
        "section-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or see the per account deposits in your account details as described in"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit documentation"])}
    },
    "fee": {
      "deposit": {
        "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To deposit base tokens on the deposit accounts of the system, please open the"])},
        "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and click on the floating button with the dollar icon at the right bottom of the page. This will result in a popup window which contains the required inputs to send some base token to one of the deposit accounts. Please fill out the inputs as described in the help elements of the inputs. After that you've filled in the necessary data, please click on the \"Done\" button at the bottom of the popup. It will immediately start sending the amount to one of the system's deposit accounts. When the request is sent, you'll see a new line in the \"Fee withdrawals\" table of the"])},
        "section-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". If the withdrawal state is \"Sent\", you should see the deposit in the \"Fee deposits\" in a minute."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])}
      },
      "description": {
        "section-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sniperbot.io charges three different costs for its use. These are the buy commission, the sell commission and the package fee. As their names suggest they are created when a buy and sell transaction was successfully executed and when a package expires. The exact rate or amount of these fees are described on the packages page."])},
        "section-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As the majority of the blockchains do not support split transactions out of the box, sniperbot.io uses a debt strategy to collect fees. This means that there is no automatic transaction which withdraws fees from the created account. Fees are accounted for in the system and paid from separate fee deposit accounts. For the maximum amount of fees owed, your package defines an upper limit. Above this limit, most of the functions will be disabled until you don't pay the previously created fees. Buy and sell commissions will be accounted for in this limit, however if there is an unpaid package fee, features will be immediately disabled. To avoid disabling features, it's highly recommended to have some spare amount on your deposit accounts to be able to immediately pay the fees when they're created."])},
        "section-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As we must know which user belongs to a certain deposit, it must be paid from one of the accounts used in the system. Furthermore, as most chains are using a different base currency, the deposits are accounted in a per chain manner. So it's recommended to store some spare base tokens on the deposit accounts of each used chain."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
      },
      "paying": {
        "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paying newly created fees is automatically done. If the user has enough base token deposited on one of the system's deposit accounts, the fee will be automatically paid after it was created. If the user does not have any base token deposited on the fee accounts, the fee will be put into the \"Commissions\" or \"Package fees\" table. These unpaid entries will be automatically paid when a new deposit arrives for the deposit accounts."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paying fees"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee documentation"])}
    },
    "general": {
      "chains": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bot supports multiple chains. To see the available chains and be able to select the active one, please click on the dropdown menu on the top right corner of the site. Please be aware that you only see the information related to the currently selected chain in all views. There is no aggregator view which summarizes information for all chains."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chains"])}
      },
      "debt": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be aware that certain functions are not available if there is a debt on your account. Some functions may be available until the debt limit defined by your package is not reached, others like withdrawal are deativated when there is any debt on your user account."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt"])}
      },
      "introduction": {
        "allowance-handling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowance Handling: Seamlessly manages allowances, eliminating the need for manual intervention."])},
        "automatic-sniping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic Sniping: Automates sniping strategies, conducting checks and executing trades for the specified signals."])},
        "checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic contract checkers: The software provides the possibility to execute automatic pair checks during the snipes."])},
        "feature-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sniperbot.io is a versatile tool designed for executing snipes across multiple blockchains and swaps. It offers a range of features to enhance user experience and streamline trading operations:"])},
        "manual-sniping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual Sniping: Allows users to manually execute snipes, providing control over timing and strategy."])},
        "multi-chain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Blockchain Support: Enables sniping across various blockchains, providing access to a range of swaps."])},
        "presets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe Presets: Pre-configured settings for quick and efficient snipe execution."])},
        "snipe-config-schemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe configuration schemas: Our software supports configuration schemas ranging from the simplest to the most advanced."])},
        "testnets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnets: Our platform supports testnets, allowing users to experiment with the platform without risking real money"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy and Sell Triggers: Enables automatic transaction execution based on predefined criteria, capitalizing on market movements."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General documentation"])}
    },
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User guide"])},
    "package": {
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package documentation"])},
      "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view the details of the currently available packages in the"])},
      "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". The selected package influences several aspects of the system. The value of these parameters are defined there."])},
      "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
    },
    "presets": {
      "general": {
        "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This system supports snipe presets. It means that the user can create preloadable configurations for their snipes. This feature could fasten up the process of sniping a contract and it make managing different frequently used configurations easier. Users can view and manage their presets in the"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
      },
      "managing": {
        "create": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create a new preset, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and click on the plus sign at the right top of the presets table or follow"])},
          "section-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this page, please fill in the fields according to the help texts of the input fields. The way of managing snipes and the meaning of the configuration options are described in details in the"])},
          "section-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as well. To save the filled configuration, please click on the floating button with the floppy icon in the right bottom of the page. This would redirect you to a new page which shows the details of the newly created snipe preset."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a preset"])}
        },
        "delete": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To delete a preset, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this menu, find the preset's row what you would like to delete. In the row, click on the red button with the x icon. This will delete the preset."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete a preset"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing presets"])},
        "update": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To update a preset, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this menu, find the preset's row what you would like to update. In the row, click on the button with the pencil icon. This will redirect you to a page where you will see the same form what you've filled when you created the preset. Here, you can modify the preset according to your needs and then you should click on the floating button with the floppy icon in the right bottom of the page. This would redirect you to a new page which shows the details of the modified snipe preset."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update a preset"])}
        },
        "view": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To see the details of a preset, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this menu, find the preset's row what you would like to see. In the row, click on the button with the eye icon. This will redirect you to a page which shows the details of the snipe preset."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View preset details"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preset documentation"])}
    },
    "snipe": {
      "checkers": {
        "behavior": {
          "section-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this system, none to multiple checkers can be defined to execute before the buy trigger, before buying and after buying. As described in the state definitions, a checker would result in either passed or not passed. If it passed then the snipe continues. If not, then it goes to the next state defined in the state definition chapters above."])},
          "section-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If multiple checkers are configured, they are running in parallel. The checking state considered to be done only if all the checkers have finished their execution. The result of multiple checkers considered to be passed only if all the checkers were passed. It results in an and relation between the checker results."])},
          "section-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that none of these checkers are 100% safe. So even if all checkers are used, scam contracts can pass through the analysis. Furthermore, sniping is about timing, so executing any action before the buy transaction can reduce the gains of a snipe. Configuring pair checkers is a tradeoff between possible gain and risk."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behavior of pair checkers"])}
        },
        "honeypot-is": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This checker runs the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check with the specified parameters. If no maximum tax is configured, it will just check whether it's a scam according to this site or not. If tax limits are configured the check will fail as well if the tax is above the specified limit."])},
          "section-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honeypot.is provides only a shallower checking which is executed in hundreds of milliseconds or a few seconds."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["honeypot.is checker"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkers"])},
        "tokensniffer": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This checker runs the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check with the specified parameters. If no parameters are configured, it will just check whether it's a scam according to this site or not. If any other parameters are configuration, it will fail if that condition is not met."])},
          "section-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokensniffer.com does a more in depth check, but depending on the contract, checking can take from several seconds to a few minutes."])},
          "section-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that tokensniffer checker may not be available in certain packages."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TokenSniffer checker"])}
        }
      },
      "general": {
        "description": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipes can generally be managed in the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this page, a table can be seen with all of the previous started snipes. The table contains information about the most important aspects of the snipe, like the buy price, the price change of the pair since the snipe has been started, the profit, etc and the action buttons. Using the action buttons, you can view the details of the snipes, cancel the snipe or start a manual sell."])},
          "section-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be aware that snipes are only shown in this table for the selected chain!"])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
        },
        "flow": {
          "behavior-of-states": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behavior of states"])},
          "buy-approval-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of the snipe where the approval happens in parallel for all the defined buy transactions where the approval was enabled."])},
          "buy-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of the snipe where the buying happens in parallel for all the defined transactions."])},
          "buy-trigger-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this state, the snipe waits for the buy trigger(s) to fullfill. For detailed description of the behavior, please check the triggers section below. Depending on the result of the trigger, the snipe will move to either to the pre-buy pair check state or to the final failed state."])},
          "done": {
            "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final state of the snipe. In this state no checks or transactions are running. From this state, it can only moved by starting a manual sell operation."])},
            "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done / Failed"])}
          },
          "post-buy-pair-check-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a checker is configured and it's successfully executed, then the snipe continues with the buy state. If it fails, then it'll go to the sell approval and then to the sell state, but it won't wait for the sell trigger but will sell the token immediately. This feature is useful if a user wants to quickly snipe a token, then check it whether it's a scam. If it is, then to sell as soon as possible. As a lot of scams won't let you sell immediately it's a dangerous configuration even with a post buy check."])},
          "pre-buy-pair-check-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a checker is configured and it's successfully executed, then the snipe continues with the buy state. If it fails, then it'll go to the final failed state and the snipe execution will stop."])},
          "pre-buy-trigger-pair-check-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This state is the first state of a snipe. If a checker is configured and it's successfully executed, then the snipe continues with the buy approval state. If it fails, then it'll go to the final failed state and the snipe execution will stop."])},
          "section-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As sniping is the most important aspect of the system, it's important to get known to how it's executed and which parameters affects which step or state during a snipe. To help to understand the execution of the snipes, this chapter gives a short introduction about the major steps of execution."])},
          "section-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipes are running through the following states:"])},
          "section-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If any of these steps are not configured, then it's simply skipped. For example, if buy approval is done manually, then the buy approval state will be skipped. If there is no automatic sell configured, after the post-buy pair check state it will jump to the done / failed state."])},
          "section-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of the steps are done together for all transactions in a snipe. So for example when a buy trigger is defined, then it'll be effective for all transactions. The same is true for the approval. If there are multiple transactions in a snipe and an approval is configured, then all approval transactions will be executed before the snipe can go to the sell state and start the sell transactions. If you would like to have different transactions with different configurations, please start multiple snipes."])},
          "sell-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this state, the bot sells the token. If there are sell triggers configured, the snipe waits for the sell trigger(s) to fullfill. For detailed description of the behavior, please check the triggers section below."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe flow"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
      },
      "managing": {
        "cancel": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To cancel a snipe, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this menu, you can find all the previous snipes. If you would like to cancel one, please click on the red button with a cross at the end of the selected row. It will send a cancellation request to the trader. Please be aware that the snipe execution is running asynchronously, so if the cancel button is pushed, it's not guaranteed that the snipe will be canceled in the state which was shown in the table at the time of cancellation."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel snipe"])}
        },
        "create": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create a snipe, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this menu, a new snipe creation could be started by clicking on the add button in the right top corner of the table. It will redirect you to a new page where you will see the snipe creation form. Multiple schemas can be used to configure a snipe. Simpler schemas are easier to configure but they do not allow to configure all aspects of the snipe. To create a snipe, the form should be filled out using the help fields and the description of the different parameters. After you've filled the necessary fields, you can click on the floating button in the bottom right with the crosshair icon. This will redirect you to a page where the details of the snipe are shown. Please be aware that this page is not updated automatically. You should refresh it if you would like to see the changed details of the snipe."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create snipe"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing snipes"])},
        "view": {
          "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To view snipes, please open the"])},
          "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this menu, you can find all the previous snipes and several important aspects of each. If you would like to read all the details of a snipe, please click on the button with the eye icon at the end of the selected row. It will redirect you to a page will show the details of the snipe are shown. Please be aware that this page is not updated automatically. You should refresh it if you would like to see the changed details of the snipe."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View snipes"])}
        }
      },
      "sell-problems": {
        "allowance-problems": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the blockchain, in the case of ERC-20 tokens, there is a contract feature called allowance. The ERC-20 description defines it as “The ERC-20 standard allows an address to give an allowance to another address to be able to retrieve tokens from it.”. As snipes are happening with swaps, the swap can only handle the transaction if it is allowed to by giving it the proper allowance. Sniperbot.io has the feature to automatically deal with allowances. However, it can be turned off and in certain cases allowance calls can also fail, like if the gas limit has been reached for the allowance transaction. If you notice an error message telling you that the snipe has failed due to an allowance problem, please double check whether you allowed automatic approval and the gas limit and gas price was appropriate."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowance problems"])}
        },
        "changed-contract-behavior": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some contracts have a backdoor which is not detected by the pair checkers executed before buying a token. This backdoor can be used to set the token to buy only mode. In these tokens, contract owners usually set this mode after a certain amount of tokens were purchased. If you see that sniperbot.io reports a failed sell transaction, please consider that it may be caused by a changed parameter in the sniped contract."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change contract behavior"])}
        },
        "changed-sell-fees": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some contracts have a backdoor which is not detected by the pair checkers executed before buying a token. This backdoor can be used to configure the sell fee. In these tokens, contract owners usually set an exceptionally high sell fee after a certain amount of tokens were purchased. If you see that sniperbot.io reports a successful sell, but you have a profit around zero, please check the contract once again, because it’s most likely a scam."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed sell fees"])}
        },
        "contract-errors": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaps can happen for a wide variety of contracts. These contracts have different quality implementations. In the past, we’ve experienced some cases in which a properly configured transaction could not be executed. For example, pancake swap has returned TRANSFER_FROM_FAILED error while everything was correctly configured, because the token contract had an incorrect transferFrom implementation."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract errors"])}
        },
        "description": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain transactions have several parameters which could result in several types of failures during a transaction. These failures range from scams, like when the contract creator has disabled sell transactions, to technical issues, like problems with the blockchain’s RPC server. Sniperbot.io tries to handle as many of these issues for you as it can, but certain problems cannot be dealt with. Let’s see some common reasons for failing transactions."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
        },
        "gas-limit-exceeded": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In sniperbot.io, you can limit the gas spent on transactions. It can occur that the blockchain has a lot of high gas limit transactions in a certain block and the chain rejects you operation."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas limit exceeded"])}
        },
        "insufficient-liquidity": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It may occur that you try to snipe but you don’t have appropriate balance for the snipe. In this case, the swap will not be able to execute the transaction. Certain swaps are returning a meaningful error, but certain ones just reject the transaction. If you see failing buy transactions in your snipes, please check whether you have proper liquidity."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient liquidity"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell problems"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sniping documentation"])},
      "triggers": {
        "behavior": {
          "section-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this system, zero to multiple triggers can be defined for both the buy and sell transactions. These triggers make the snipe wait until a certain event is happening on the chain. If there are no triggers defined, then the transactions execute immediately. If one or more triggers are configured, the snipe waits until they are finished or canceled. Triggers can have three results. They can fire, timeout or be canceled. A trigger fires if its precondition is fulfilled (for example price changed by 200%). If it fired, that would result in executing the transaction which means buy for buy triggers and sell for sell trigger. Timeout means that the trigger was executed for the configured times (defined by the poll count parameter). If a timeout occurs, the snipe will not continue the execution. Polls are done in every 1.5s, so for example a price change buy trigger with a poll count 100 would mean that if the price changes to the desired level in the 150s, then it’s bought, otherwise the trade is aborted. Cancellation leads to the same abortion as the timeout, but it’s user initiated."])},
          "section-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If multiple triggers are configured, they are running in parallel and when the first gives a result, it will be the final result and the rest of the triggers will be canceled. So let's say a price change and a timeout trigger are configured. Whether the timeout or the price change trigger fires at first, the other will be ignored and the snipe will continue."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behavior of triggers"])}
        },
        "price-change": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defines a price change limit at which the trigger will fire. For example, if the change is configured to be 200% and the price of the token changes above this limit, it'll fire. If a poll count is configured and it expires then the trigger will timeout. If a change below 100% is configured, then it'll be checked for a change below that limit. For example a 50% change would mean that if the price drops to half, it'll fire."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change"])}
        },
        "price-change-triggered-take-profit": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defines a price change triggered take profit trigger. It has two parameters. One is the trigger change and the second is the change. When the price level defined by the trigger change is reached, then the second phase of the trigger will be activated which monitors the price for falling below a certain limit defined by the change parameter. It is useful for defining sell triggers to protect your profit. For example, if you hope that the price will reach 10x, but you are not quite sure that it will reach that level, then you can set a 10x price price change trigger to sell when it reached 10x and a price change triggered take profit with the parameters trigger change 3x and change 2x which will activate when the price has reached 3x and if after 3x happened, than monitors the price for falling below 2x. If it is reached, then it will sell at 2x instead of keeping a loosing snipe."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change triggered take profit"])}
        },
        "swap-contract-creation": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defines a trigger for swap contract creation. Useful for using as a buy trigger when the token's swap pair contract has not been created yet."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap contract creation"])}
        },
        "timeout": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defines a timeout trigger. It's useful if you would like to perform the buy or sell transaction even if other triggers did not fire. For example, if there is a sell trigger for 10x, but you would like to sell after 1 day even if the 10x was not reached. In this case, you would have to define a price change and a timeout trigger. If the price change trigger would fire, then the timeout trigger will be canceled. If the price change would not but the timeout would fire, then the token will be sold even if the 10x was not reached."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggers"])},
        "trading-enabled-flag": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defines a trigger for enabling trading flag (tradingEnabled or swapEnabled functions of the contract). Useful for using as a buy trigger when the token's swap pair contract has been already created but it has one of the enabled flag."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading enabled flag"])}
        },
        "trailing-stop-loss": {
          "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defines a trailing stop loss trigger. The trailing stop loss trigger dynamically adjusts the stop loss price as the token's value fluctuates. It sets a percentage below the token's peak price since buying, aiming to protect gains while allowing for potential further upside. For example, if set at 50%, and the token's price rises by 500%, the stop loss will be recalculated to maintain a 50% buffer below the highest price reached. If the token's price later drops by more than this percentage from its peak, the trigger will activate, helping to mitigate losses by selling the token."])},
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailing stop loss"])}
        }
      }
    },
    "withdraw": {
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal documentation"])},
      "section-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To withdraw some amount from your user account, please open the"])},
      "section-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". In this menu, you should find the account from which you would like to withdraw from. In the row of that account, please click on the up arrow. This brings up a popup window. In this window you can fill out the details of the withdrawal request, then click on the \"Done\" button to start the withdrawal process."])},
      "section-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be aware that withdrawal can only be done when there is no debt on your user!"])},
      "section-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the withdrawal was created, you can monitor it's state in the"])},
      "section-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or see the per account withdrawals in your account details as described in"])},
      "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
    },
    "copy-trade": {
      "general": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "description": {
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Copy Trade feature allows you to mirror the actions of a copy trader account with one of your own accounts. You can copy the creation, cancellation, and manual sell actions of the copy trader."])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: Because blockchain transactions are asynchronous and the amount of transactions can vary even within the same block, it is not guaranteed that you will achieve exactly the same profit as the copy trader."])}
        },
        "restrictions": {
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
          "followable-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only specific accounts, not users, can be configured to be followable."])},
          "snipe-amount-limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The snipe amount limits of your package still apply. If you have a lower package, your snipe amounts may be limited."])},
          "snipe-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The snipe limit of the copied account still applies. If the copy trader has more snipes in their package, some snipes may not start on your account."])},
          "base-token-snipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only base token snipes can be copied."])},
          "account-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is your responsibility to ensure your account has sufficient funds. Without enough balance, snipes may fail."])}
        }
      },
      "copy-trader-accounts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy trader accounts"])},
        "managing": {
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing copy trader accounts"])},
          "creating-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create a new copy trader account, go to the copy trade menu and click the plus icon at the top right corner of the Copy Trader Accounts table."])}
        },
        "restrictions": {
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
          "eligibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only certain people, enabled by the administrators, can be copy traders."])},
          "conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once an account is set as a copy trader account, it cannot be converted back to a normal account."])},
          "base-token-trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy trader accounts can only execute base token trades."])},
          "single-account-snipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy trader accounts can only execute snipes where a single account is used."])},
          "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address of a copy trader account is visible to all other users."])}
        }
      },
      "copy-configurations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy configurations"])},
        "description": {
          "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "adding-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add copy configurations by clicking the plus icon at the end of the row in the Copy Trader Accounts table."])},
          "multiple-uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier accounts can be used for other purposes but it is not recommended."])},
          "pausing-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy configurations can be paused. When paused, no new snipes will be created when the copy trader creates one."])},
          "deleting-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy configurations can also be deleted."])},
          "snipe-amount-limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum and maximum snipe amounts can be configured. If the copy trader's amount is higher than your set limit, it will be adjusted to match your limit. If no limit is configured or the copy trader's amount exceeds the minimum or maximum snipe limit, the more restrictive limit will be used."])},
          "automatic-balance-ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set an automatic balance ratio to more closely follow the copy trader's amount ratio. If set, the snipe amount will be calculated based on the base token balance ratio between accounts. Otherwise, the amount will be copied 1:1."])}
        }
      }
    }
  },
  "user-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User details was successfully updated."])},
  "voucher-amount-usdt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " USDT"])},
  "voucher-was-successfully-redeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher was successfully redeemed"])},
  "vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
  "websocket-lost-multi": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Websocket connection lost for ", _interpolate(_list(0)), " component. Trying to reconnect..."])},
  "websocket-lost-single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Websocket connection lost. Trying to reconnect..."])},
  "will-renew-on-expirydate-tolocaledatestring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Will renew on ", _interpolate(_list(0))])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])},
  "withdrawal-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal was successfully created"])},
  "withdrawal-requires-2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal requires two factor authentication"])},
  "withdrawal-states": {
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "waitingForVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for verification"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])}
  },
  "withdrawals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawals"])},
  "your-package-is-not-available-anymore-it-will-not-be-renewed-automatically-please-activate-another-one-when-the-previous-has-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your package is not available anymore. It will not be renewed automatically! Please activate another one when the previous has expired."])},
  "basic-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic automation signals"])},
  "premium-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium automation signals"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
}